<template>
    <div class="operationPromotionStorePromotionAdd">
        <div class="header">
            <el-breadcrumb separator="/">
                <el-breadcrumb-item>营销工具</el-breadcrumb-item>
                <el-breadcrumb-item>优惠券</el-breadcrumb-item>
            </el-breadcrumb>
            <div class="headerTitle">创建优惠券</div>
        </div>
        <div class="banner">
            <div class="bannerHeader">
                <div class="bannerHeaderOne">
                    <span class="isActive">1</span>
                    <b>设置优惠门槛及内容</b>
                </div>
                <img style="height: 14px;" v-if="this.$route.name == 'operationPromotionCouponOne' && !this.$route.query.id" :src= "require('./../../../../assets/images/serviceData/xian.png')" alt="">
                <img style="height: 14px;" v-if="this.$route.name == 'operationPromotionCouponTwo' || this.$route.query.id" :src= "require('./../../../../assets/images/serviceData/chengxian.png')" alt="">
                <div class="bannerHeaderOne">
                    <span :class="this.$route.name == 'operationPromotionCouponTwo' || this.$route.query.id ? 'isActive' : ''">2</span>
                    <b>设置主商品</b>
                </div>
            </div>
        </div>
        <div class="bannerFill">
            <router-view></router-view>
        </div>
    </div>
</template>

<script>
    export default {
        name: "CouponAdd"
    }
</script>

<style scoped lang="scss">
    .operationPromotionStorePromotionAdd{
        .header{
            .headerTitle{
                color: #343434;
                font-size: 18px;
                padding-top: 16px;
                font-weight: bold;
            }
        }
        .banner{
            background: #ffffff;
            padding: 60px 20px;
            margin-top: 20px;
            display: flex;
            justify-content: center;
            .bannerHeader{
                display: flex;
                img{
                    margin-top: 20px;
                }
                .bannerHeaderOne{
                    display: flex;
                    flex-direction: column;
                    align-items: center;
                    span{
                        display: inline-block;
                        width: 48px;
                        height: 48px;
                        background: #DCDCDC;
                        color: #ffffff;
                        font-size: 24px;
                        text-align: center;
                        line-height: 48px;
                        border-radius: 50%;
                    }
                    .isActive{
                        background: #FD4446;
                    }
                    b{
                        font-size: 16px;
                        font-weight: 400;
                        color: #333333;
                        line-height: 1;
                        margin-top: 20px;
                    }
                }
            }
        }
        .bannerFill{
            background: #ffffff;
        }
    }
</style>